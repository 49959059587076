import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import yamlData from "../content/questions.yaml"
import {
  arrow,
  list,
  titleQuestions,
  image,
  blocQuestion,
  losange,
  principalTitle
} from "../styles/questions.module.css"
import { Col, Container, Row } from "react-bootstrap"

const Questions = ({ page }) => {

  const images = useStaticQuery(graphql`
    query {
      epave: file(relativePath: { eq: "epave_rouille.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      choisir: file(relativePath: { eq: "question2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      recyclage: file(relativePath: { eq: "recyclage.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

const selectContent = () => {
  if(page === "destruction")
    return {
      titleService: yamlData.destruction.titleService,
      questionService1: yamlData.destruction.questionService1,
      questionChoisir1: yamlData.destruction.questionChoisir1
    }
  if(page === "casse")
    return {
      titleService: yamlData.casse.titleService,
      questionService1: yamlData.casse.questionService1,
      questionChoisir1: yamlData.casse.questionChoisir1
    }
  if(page === "centre")
    return {
      titleService: yamlData.centre.titleService,
      questionService1: yamlData.centre.questionService1,
      questionChoisir1: yamlData.centre.questionChoisir1
    }
  if(page === "rachat")
    return {
      titleService: yamlData.rachat.titleService,
      questionService1: yamlData.rachat.questionService1,
      questionChoisir1: yamlData.rachat.questionChoisir1
    }
  return {
    titleService: yamlData.epave.titleService,
    questionService1: yamlData.epave.questionService1,
    questionChoisir1: yamlData.epave.questionChoisir1
  }
}

  return (
    <Container>
      <Row> 
        <Col className={principalTitle}>
          <div>Informations complémentaires</div>
          <div className={losange} />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={4} className={blocQuestion}>
          <Img
          fluid={images.epave.childImageSharp.fluid}
          alt="dessin epave de voiture"
          className={image}
          /> 

          <h3 className={titleQuestions}>{selectContent().titleService}</h3>
          <ul className={list}>
            <li>
              <span className={arrow}>&raquo;</span>{" "} {selectContent().questionService1}
            </li>
            <li>
              <span className={arrow}>&raquo;</span> {yamlData.questionService2}
            </li>
            <li>
              <span className={arrow}>&raquo;</span> {yamlData.questionService3}
            </li>
          </ul>
        </Col>
        <Col lg={4} md={4} sm={4} className={blocQuestion}>
          <Img
          fluid={images.choisir.childImageSharp.fluid}
          alt="dessin epave de voiture"
          className={image}
          /> 

          <h3 className={titleQuestions}>{yamlData.titleChoisir}</h3>
          <ul className={list}>
            <li>
              <span className={arrow}>&raquo;</span>{" "} {selectContent().questionChoisir1}
            </li>
            <li>
              <span className={arrow}>&raquo;</span> {yamlData.questionChoisir2}
            </li>
            <li>
              <span className={arrow}>&raquo;</span> {yamlData.questionChoisir3}
            </li>
          </ul>
        </Col>
        <Col lg={4} md={4} sm={4} className={blocQuestion}>
          <Img
          fluid={images.recyclage.childImageSharp.fluid}
          alt="dessin epave de voiture"
          className={image}
          /> 

          <h3 className={titleQuestions}>{yamlData.titleRecyclage}</h3>
          <ul className={list}>
            <li>
              <span className={arrow}>&raquo;</span> {yamlData.questionRecyclage1}
            </li>
            <li>
              <span className={arrow}>&raquo;</span> {yamlData.questionRecyclage2}
            </li>
            <li>
              <span className={arrow}>&raquo;</span> {yamlData.questionRecyclage3}
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}
export default Questions
