import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import "../../../styles/global.css"
import {
  bold,
  departmentTitle,
  link,
  principalTitleInformations,
  subTitleInfo,
} from "../styles/informations.module.css"
import yamlData from "../../department/content/informations.yaml"
import { Col, Container, Row } from "react-bootstrap"
import Img from "gatsby-image"

const Informations = (props) => {
  const image = useStaticQuery(graphql`
    query {
      information: file(relativePath: { eq: "epaviste_auto_3_dulin.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Container>
      <h1 className={principalTitleInformations}>
        {yamlData.title}
        <br />
        <span className={departmentTitle}>
          {props.department === "Val-dOise" ? "val-d'Oise" : props.department}
        </span>
      </h1>
      <Row className="animated fadeInUp delay-0.2s">
        <Col lg={4} sm={5} xs={12}>
          <figure>
            <Img
              fluid={image.information.childImageSharp.fluid}
              alt="casse_auto_idf_dulin"
            />
          </figure>
        </Col>
        <Col lg={8} sm={7} xs={12}>
          <h3>{yamlData.subtitle}</h3>
          <p className="paragraphe">
            A Savoir :
            <br />- {yamlData.liste.item1}
            <br />- {yamlData.liste.item2}
          </p>
          <p className="paragraphe">
            On distingue deux types d’épaves :
            <br />- {yamlData.liste.item3}
            <br />- {yamlData.liste.item4}
          </p>
          <p className="paragraphe">{yamlData.paragraphe}</p>
        </Col>
      </Row>

      <div className="animated fadeInUp delay-0.2s">
        <h2 className={subTitleInfo}>
          {yamlData.gratuit.title}
          {props.department}
        </h2>
        <p className="paragraphe">
          {yamlData.gratuit.paragraphe}
          <span className={bold}>{" "}{yamlData.gratuit.span}</span>.
        </p>
        <p className="paragraphe">
          {yamlData.gratuit.paragraphe2}
          <span className={bold}>{" "}{yamlData.gratuit.span2}{" "}</span>.
        </p>
        <p className="paragraphe">
          -{yamlData.gratuit.paragraphe3a}
          <br />-{yamlData.gratuit.paragraphe3b}
        </p>
      </div>

      <h2 className={subTitleInfo}>{yamlData.agree.title}</h2>
      <p className="paragraphe">
        {yamlData.agree.paragraphe1a}
        <span className={bold}>{yamlData.agree.span}</span>
        {yamlData.agree.paragraphe1b}
      </p>
      <p>
        {yamlData.agree.paragraphe2}
        <Link className={link} to="/contact">
          {yamlData.agree.link}
        </Link>
      </p>

      <h2 className={subTitleInfo}>{yamlData.enlevement.title}</h2>
      <p className={bold}>{yamlData.enlevement.paragraphe1}</p>
      <p className={bold}>{yamlData.enlevement.paragraphe2}</p>
      <p>
        <span className={bold}>{yamlData.enlevement.span}</span>
        {yamlData.enlevement.paraghraphe3a}
        <br />
        {yamlData.enlevement.paraghraphe3b}
      </p>
      <p>
        <span className={bold}>{yamlData.enlevement.span2}</span>
        {yamlData.enlevement.paraghraphe4a}
        <br />
        {yamlData.enlevement.paraghraphe4b}
      </p>
      <p>
        <span className={bold}>{yamlData.enlevement.paragraphe5}</span>(Pièce
        d'Identité).
      </p>
    </Container>
  )
}

export default Informations
