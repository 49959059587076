import React from "react"
import Layout from "../../components/common/layout"
import Hr from "../../components/common/hr"
import Information from "./components/informations"
import Step from "./components/step"
import Estimate from "./components/estimate"
import Questions from "./components/questions"
import Seo from "../../components/common/seo"

const department = ({ pageContext: { department } }) => (
  <Layout>
    <Seo
      title={`Enlèvement d'épave ${department} gratuit, service 6j/7 - Epaviste Dulin`}
      description={`Spécialistes de l'enlèvement d'épave gratuit ${department}, nous vous proposons un service rapide 6j/7. Notre équipe répond à toutes vos questions sur l'enlèvement de voitures hors d'usage.`}
    />
    <Hr />
    <Information department={department} />
    <Step />
    <Estimate />
    <Questions />
  </Layout>
)

export default department
